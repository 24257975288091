import { tns } from "tiny-slider/src/tiny-slider";

(function() {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    const sliders = document.querySelectorAll('.js-slider');

    if(!sliders.length) return;

    sliders.forEach(function(slider) {
      slider.classList.add('is-init');

      const tiny_slider = tns({
        container: slider.querySelector('.js-slider-container'),
        // autoWidth: true,
        fixedWidth: parseInt(slider.dataset.sliderWidth),
        speed: 500,
        loop: false,
        prevButton: slider.querySelector('.js-slider-prev'),
        nextButton: slider.querySelector('.js-slider-next'),
        navPosition: "bottom",
      });
    });
  });
})();
