import floating_label from "floating-label/dist/floatingLabel";

(function () {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    floating_label.init({
      floatingClassName: 'is-floating',
    });

    document.querySelectorAll('label').forEach(function(item) {
      item.classList.add('has-floating');
    });
  });
})();
