import { throttle, debounce } from 'throttle-debounce/dist/index.esm';

(function() {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    const navigation = document.querySelector('#navigation');
    const buttons = navigation.querySelectorAll('.js-navigation-button');
    let is_hidden = navigation.getAttribute('aria-hidden') === "true";

    const show_target = function (button) {
      const target = document.querySelector('#' + button.getAttribute('aria-controls'));
      button.setAttribute('aria-expanded', "true");
      target.focus();
    };

    const hide_target = function (button) {
      button.setAttribute('aria-expanded', "false");
    };

    const hide_all = function () {
      buttons.forEach(function(button) {
        hide_target(button);
      });
    };

    const update = debounce(100, function () {
      const tmp = navigation.getAttribute('aria-hidden') === "true";
      if(is_hidden != tmp) {
        is_hidden = tmp;
        hide_all();
      }
    });

    buttons.forEach(function(button) {
      const target = document.querySelector('#' + button.getAttribute('aria-controls'));
      let is_hovered = false;
      button.setAttribute('aria-expanded', "false");
      target.setAttribute('tabindex', "-1");

      button.addEventListener('click', function (event) {
        const expanded = button.getAttribute('aria-expanded') === "true";
        if (!expanded) {
          event.preventDefault();
          show_target(button)
        }
      });

      if(!document.documentElement.classList.contains('touchevents')) {
        button.parentNode.addEventListener('mouseenter', function (event) {
          show_target(button);
          is_hovered = true;
        });

        button.parentNode.addEventListener('mouseleave', function (event) {
          hide_target(button);
          is_hovered = false;
        });
      }
    });

    window.addEventListener('resize', update);
  });
})();
