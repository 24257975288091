import { throttle, debounce } from 'throttle-debounce/dist/index.esm';

(function() {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    const burgers = document.querySelectorAll('.js-burger');

    burgers.forEach(function (burger) {
      const target = document.querySelector('#' + burger.getAttribute('aria-controls'));
      let state = false;
      let is_togglable = false;

      const toggle_target = function () {
        burger.setAttribute('aria-expanded', !state);
        target.setAttribute('aria-hidden', state);
        state = !state;

        if(state) {
          target.focus();
        }
      };

      const activate = function () {
        burger.setAttribute('aria-expanded', "false");
        target.setAttribute('aria-hidden', "true");
        target.setAttribute('tabindex', "-1");
        state = false;
      };

      const deactivate = function () {
        burger.removeAttribute('aria-expanded');
        target.removeAttribute('aria-hidden');
        target.removeAttribute('tabindex');
        state = true;
      };

      const update = debounce(100, function () {
        init();
      });

      const init = function () {
        if(is_togglable !== (getComputedStyle(burger).display !== "none")) {
          is_togglable = getComputedStyle(burger).display !== "none";
          is_togglable ? activate() : deactivate();
        }
      };

      burger.addEventListener('click', toggle_target);

      window.addEventListener('resize', update);

      init();
    });
  });
})();
