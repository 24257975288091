import objectFitImages from "object-fit-images/dist/ofi.common-js";

(function () {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    const images = document.querySelectorAll('.js-object-fit');

    if(!images.length) return;

    objectFitImages(images);
  });
})();
