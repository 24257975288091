/**
 * _browser.js
 * Display a message to non supported browser.
 *
 * If needed you can update the markup of the message
 * and/or the body of browserIsCompatible() function to adjust the test based on which browsers you want to support.
 */

(function() {
  'use strict';

  var label_text = document.createTextNode("Mettre à jour maintenant");
  var label = document.createElement("span");
  var button = document.createElement("a");
  var actions = document.createElement("div");
  var content_text = document.createTextNode("Mettez à jour votre navigateur pour afficher correctement ce site Web.");
  var content = document.createElement("p");
  var title_text = document.createTextNode("Votre version de navigateur est obsolète.");
  var title = document.createElement("strong");
  var html = document.createElement('div');

  label.classList.add('c-button__text');
  button.classList.add('c-button');
  button.classList.add('c-browser__button');
  actions.classList.add('c-browser__actions');
  content.classList.add('c-browser__content');
  title.classList.add('c-browser__title');
  html.classList.add('c-browser');

  button.setAttribute('href', "http://outdatedbrowser.com/fr");
  button.setAttribute('target', "_blank");
  button.setAttribute('rel', "noopener");

  label.appendChild(label_text);
  content.appendChild(content_text);
  title.appendChild(title_text);
  button.appendChild(label);
  actions.appendChild(button);
  html.appendChild(title);
  html.appendChild(content);
  html.appendChild(actions);

  function browserIsCompatible(){
    // By default we treat all browsers has compatible
    var isCompatible = true;

    /*
     * No IE versions supports @supports{} and if we rely on caniuse stats this feature is pretty well supported :
     * https://caniuse.com/#search=supports
     * So this must cover our comptaibility expectations (usually last 2 versions)
     */
    var cssSupports = 'CSS' in window && 'supports' in window.CSS || 'supportsCSS' in window;
    // Target IE11 only : https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    // If doesn't support @supports{} and is not IE11
    if(!cssSupports && !isIE11){
      isCompatible = false;
    }

    return isCompatible;
  }

  // When DOM is ready, if browser is not supported then we display the disclaimer message.
  document.addEventListener("DOMContentLoaded", function() {
    if(!browserIsCompatible()){
      document.querySelectorAll('body')[0].prepend(html);
    }
  });

})();
